type TStartProps = {
  filled: number;
  stroked: number;
};

const Stars = ({ filled, stroked }: TStartProps) => {
  return null;
};

export default Stars;
